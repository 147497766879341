import { defineStore } from 'pinia'

export const useAuthStore = defineStore('auth', {
  state: () => ({
    access: null,
    refresh: null,
    user: null,
    email: null,
    shownGuideModal: false,
    isLogin: false,
    isEnterApp: false,
  }),
  actions: {
    async login({ password, email }) {
      let err
      const apiUrl = useRuntimeConfig().public.apiUrl

      const { data, error } = await useAsyncData(`login`, () =>
        $fetch(`/auth/jwt/login/`, {
          baseURL: apiUrl,
          method: 'POST',
          body: {
            email,
            password,
          },
          onResponseError({ response }) {
            err = response
          },
        }),
      )

      if (data.value) {
        this.access = data.value.access
        this.refresh = data.value.refresh
        return data.value
      }

      if (error.value) {
        clearNuxtData('login')
        console.log('error login', error)
        return err
      }
    },
    async refreshToken() {
      const apiUrl = useRuntimeConfig().public.apiUrl
      const { data, error } = await useAsyncData('refresh', () =>
        $fetch(`/auth/jwt/refresh/`, {
          baseURL: apiUrl,
          method: 'POST',
          body: {
            refresh: this.refresh,
          },
        }),
      )
      if (data.value) {
        this.access = data.value.access
      }
      if (error.value) {
        this.access = null
        this.refresh = null
        console.log(error.value)
        return error.value
      }
    },
    async register(payload) {
      const apiUrl = useRuntimeConfig().public.apiUrl

      const { data, error } = await useAsyncData('register', () =>
        $fetch(`/user/create_verification_email/`, {
          baseURL: apiUrl,
          method: 'POST',
          body: payload,
        }),
      )

      if (data.value) {
        return data.value
      }

      if (error.value) {
        return error
      }
    },
    async getCurrentUser() {
      const apiUrl = useRuntimeConfig().public.apiUrl

      const { data, error } = await useAsyncData('user', () =>
        $fetch(`/user/get_current_user/`, {
          baseURL: apiUrl,
          method: 'GET',
        }),
      )

      if (data.value) {
        this.user = data.value
      }

      if (error.value) {
        console.log(error.value)
        clearNuxtData('user')
        return error.value
      }
    },

    async logUserOut() {
      const apiUrl = useRuntimeConfig().public.apiUrl
      const { data, error } = await useAsyncData('logout', () =>
        $fetch(`/user/logout/`, {
          baseURL: apiUrl,
          method: 'POST',
          body: {
            refresh: this.refresh,
          },
        }),
      )
      this.access = null
      this.refresh = null
      this.user = null
      if (error.value) {
        console.log(error.value)
        clearNuxtData('logout')
      }
    },
    async deleteAccount() {
      const apiUrl = useRuntimeConfig().public.apiUrl
      const { data, error } = await useAsyncData('userDelete', () =>
        $fetch(`/user/${this.user.id}/`, {
          baseURL: apiUrl,
          method: 'DELETE',
        }),
      )
      if (error.value) {
        console.log(error.value)
        clearNuxtData('userDelete')
      }
    },
    async cancelSubscription() {
      const apiUrl = useRuntimeConfig().public.apiUrl
      const { data, error } = await useAsyncData('cancelSub', () =>
        $fetch(`/payment/cancel_membership/`, {
          baseURL: apiUrl,
          method: 'POST',
        }),
      )
      if (error.value) {
        console.log(error.value)
        return error.value
      }
    },
    async updateUser({ username, plan, safeMode }) {
      const apiUrl = useRuntimeConfig().public.apiUrl
      const { data, error } = await useAsyncData('userUpdate', () =>
        $fetch(`/user/${this.user.id}/`, {
          baseURL: apiUrl,
          method: 'PUT',
          body: {
            username: username,
            plan: plan,
            safeMode: safeMode,
          },
        }),
      )
      if (data.value) {
      }
      if (error.value) {
        console.log(error.value)
        clearNuxtData('userUpdate')
      }
    },
    async updateUsername(username) {
      const apiUrl = useRuntimeConfig().public.apiUrl
      let err
      const { data, error } = await useAsyncData('usernameUpdate', () =>
        $fetch(`/user/${this.user.id}/`, {
          baseURL: apiUrl,
          method: 'PUT',
          body: {
            username: username,
          },
          onResponseError({ response }) {
            err = response._data.username
          },
        }),
      )
      if (error) {
        return err
      }
    },
    async updatePassword({ oldPassword, newPassword, confirmNewPassword }) {
      const apiUrl = useRuntimeConfig().public.apiUrl
      let err
      const { data, error } = await useAsyncData('userPasswordUpdate', () =>
        $fetch(`/user/change_password/`, {
          baseURL: apiUrl,
          method: 'POST',
          body: {
            old_password: oldPassword,
            password: newPassword,
            confirm_password: confirmNewPassword,
          },
          onResponseError({ response }) {
            err = response._data.password
            console.log(err)
          },
        }),
      )

      if (data.value) {
        const { error: logoutError } = await $fetch('/user/logout/', {
          baseURL: apiUrl,
          method: 'POST',
          body: {
            refresh: this.refresh,
          },
        })

        if (logoutError) {
          console.log(logoutError._data)
          return logoutError._data
        }
        this.access = data.value.access
        this.refresh = data.value.refresh
      }

      if (error) {
        return err
      }
    },
    async takeDataByGoogleToken(googleToken) {
      const apiUrl = useRuntimeConfig().public.apiUrl

      try {
        const data = await $fetch('/user/oauth/', {
          baseURL: apiUrl,
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: {
            token: googleToken,
          },
        })

        this.access = data.access_token
        this.refresh = data.refresh_token
      } catch (error) {
        console.error('Fetch error:', error)
        if (error.response && error.response.data) {
          console.error('Server responded with an error:', error.response.data)
        }
      }
    },
    setRegistEmail(value) {
      this.email = value
    },
    deleteEmail() {
      this.email = ''
    },
    changeIsLogin(value) {
      this.isLogin = value
    },
    changeIsEnter() {
      this.isEnterApp = true
    },
    setTokens(value) {
      this.access = value.access
      this.refresh = value.refresh
    },
    changeGuideModal(value) {
      this.shownGuideModal = value
    },
    removesTokens() {
      this.access = null
      this.refresh = null
    },
  },
  getters: {
    getAccessToken: (state) => state.access,
    getRefreshToken: (state) => state.refresh,
    getUser: (state) => state.user,
    getEmail: (state) => state.email,
    getGuideModal: (state) => state.shownGuideModal,
    getIsLogin: (state) => state.isLogin,
    getIsEnter: (state) => state.isEnterApp,
  },
  persist: [
    {
      paths: [
        'access',
        'refresh',
        'user.credits',
        'user.subscription_plan',
        'user.is_edit_available',
        'user.username',
      ],
      storage: persistedState?.localStorage,
    },
  ],
})
