import mixpanel from 'mixpanel-browser'

export default defineNuxtPlugin((nuxtApp) => {
  const runtimeConfig = useRuntimeConfig().public
  mixpanel.init(runtimeConfig.mixpanel, {
    cross_subdomain_cookie: true,
  })
  const actions = {
    identify: (id) => {
      mixpanel.identify(id)
    },
    alias: (id) => {
      mixpanel.alias(id)
    },
    track: (name, props) => {
      mixpanel.track(name, props)
    },
    people: {
      set: (props) => {
        mixpanel.people.set(props)
      },
    },
  }
  nuxtApp.provide('mixpanel', actions)
})
